import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, Button, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useLogout, useMe } from "components/use-hooks";
// import logo from "assets/logo.png";

import './app-header.scss';

const DropMenu = ({ me }) => {

    const { logout } = useLogout();

    return (
        <Menu>
            <Menu.Item key="profile-header">

                <Link to={ `/users/${ me.id }` } >
                    Your Profile
                </Link>

            </Menu.Item>
            <Menu.Item key="logout-header">
                <Button type="link" onClick={ () => logout({ redirectRoute: "/" }) }>
                    Logout
                </Button>
            </Menu.Item>
        </Menu>
    )
};


const AppHeader = () => {

    const [ dateState, setDateState ] = useState(new Date());

    useEffect(() => {
        setInterval(() => setDateState(new Date()), 1000);
    }, []);

    const { me } = useMe();

    return (
        <header className="ant-layout-header header">
            <div className="logo">
                {/* <div className="logo-box">
                    <img src={ logo } alt="logo-elfe" />
                </div> */}

                <a className="navbar-brand" href="/">
                    <span className="bold me-2">Translations</span>
                    YAS
                </a>
            </div>
            <div className="real-time fw-600">
                {/* { dateState.toLocaleDateString( 'en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                })} */}
                { dateState.toLocaleString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: false,
                }) }
            </div>
            <Dropdown overlay={ <DropMenu me={ me } /> } trigger={ [ 'click' ] }>
                <span className="ant-dropdown-link fw-600 my-profile-link" onClick={ e => e.preventDefault() }>
                    { me.email } <DownOutlined />
                </span>
            </Dropdown>
        </header>
    );
};

export default AppHeader;