import { gql } from '@apollo/client';

export const LANGUAGE_UPDATE = gql`
    mutation LanguageUpdate(
        $input: LanguageFieldsInput
    ) {
        languageUpdate(
            input: $input
        ){
            label
            message
            language{
                id
                label
                slug
                status
                sync_date
            }
        }
    }
`;

export const LANGUAGE_CREATE = gql`
    mutation LanguageCreate(
        $input: LanguageFieldsInput!
    ) {
        languageCreate(
            input: $input
        ){
            label
            message
            language{
                id
                label
                slug
                status
                sync_date
            }
        }
    }
`;

export const LANGUAGE_DELETE = gql`
    mutation LanguageDelete(
        $id: ID!
    ) {
        languageDelete(
            id: $id
        ){
            label
            message
        }
    }
`;