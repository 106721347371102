const userStatus = [
    { text: 'new', value: 'new' },
    { text: 'active', value: 'active' },
    { text: 'block', value: 'block' }
];

const userRoles = [
    { text: 'developer', value: 'developer' },
    { text: 'admin', value: 'admin' },
    { text: 'guest', value: 'guest' }
];

const UserModel = {
    Status: userStatus,
    Roles : userRoles,
};

export default UserModel;