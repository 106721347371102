import React from 'react';
import { Form, Select } from 'antd';


const { Option } = Select;

const TranslateSeparatorSelectField = () => {

    return (

        <Form.Item 
            name="separator"
            label="Separator" 
            rules={[
                { required: true, message: 'Please input language' }
            ]}
        >
            <Select 
                key="separator-select"
                className="select-separator ms-2"
                placeholder="Select Separator"
            >

                <Option value="bottom_dash">bottom dash: " _ "</Option>
                <Option value="dot">dot: " . "</Option>

            </Select>

        </Form.Item>
        
    );
}

export default TranslateSeparatorSelectField;