import { gql } from '@apollo/client';


export const USER_LOGIN = gql`
    mutation UserLogin($data: UserLoginInput!) {
        userLogin(input: $data){
            access_token
        }
    }
`;

export const USER_LOGOUT = gql`
    mutation UserLogout{
        userLogout {
            status
            message
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation UserForgotPassword($input: UserForgotPasswordInput!) {
        userForgotPassword(input: $input){
            label
            message
        }
    }`;

export const CHANGE_FORGOT_PASSWORD = gql`
    mutation ChangeForgotPassword(
        $password: String!
        $token: String!
    ){
        userUpdateForgotPassword(
            password: $password
            token: $token
        ){
            label
            message
        }
    }`;

    export const USER_CHANGE_PASSWORD = gql`
    mutation UserChangePassword(
        $input: UserChangePassword!
        ) {
        userChangePassword(input: $input ){
            label
            message
        }
    }`;

export const USER_UPDATE = gql`
    mutation UserUpdate(
        $input: UserFieldsInput!
    ) {
        userUpdate(
            input: $input
        ){
            label
            message
            user{
                id
                name
                email
                status
                role
                lang
                is_online
                last_visit_at
                updated_at
                created_at
            }
        }
    }
`;

export const USER_CREATE = gql`
    mutation UserCreate(
        $input: UserFieldsInput!
    ) {
        userCreate(
            input: $input
        ){
            label
            message
            user{
                id
                name
                email
                status
                role
                lang
                is_online
                last_visit_at
                updated_at
                created_at
            }
        }
    }
`;

export const USER_DELETE = gql`
    mutation UserDelete(
        $id: ID!
    ) {
        userDelete(
            id: $id
        ){
            label
            message
        }
    }
`;
