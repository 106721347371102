import React from "react";
import { useMutation } from "@apollo/client";
import { Form, Input, Button, Switch } from 'antd';

import { LANGUAGE_UPDATE } from "graphql/mutation/language-gql";

import { errorNotification, successNotification } from "components/request-result";


const LanguageEditForm = ( { language } ) => {

    const [ form ] = Form.useForm();

    const [languageUpdateRequest, { loading }] = useMutation( LANGUAGE_UPDATE,
        {
            update(cache, { data }) {

                const {
                    languageUpdate : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(
        
        <Form
            key="edit-language"
            initialValues={ {
                label: language?.label,
                slug: language?.slug,
                status: language?.status
            } }
            form={ form }
            onFinish={(values) => {

                languageUpdateRequest({
                    variables: {
                        input: { 
                            id: language.id, 
                            ...values 
                        }
                    }
                }).catch((error) => {
                    errorNotification(error);
                });

            }}
            className="edit-language"
        >

            <div className="field-group">

                <Form.Item 
                    name="label"
                    label="Label" 
                    rules={[
                        { required: true, message: 'Please input Label' }
                    ]}
                >
                    <Input name="label" />
                </Form.Item>

                <Form.Item 
                    name="slug"
                    label="Slug" 
                    rules={[
                        { required: true, message: 'Please input Slug' }
                    ]}
                >
                    <Input name="slug" />
                </Form.Item>

                <Form.Item 
                    label="Active" 
                    valuePropName="checked"
                    name="status"
                >
                    <Switch />
                </Form.Item>

            </div>

            <div className="form-actions text-right mt-4">
                <Button
                    loading={ loading }
                    disabled={ loading }
                    type="primary"
                    htmlType="submit"
                >
                    Save
                </Button>
            </div>
            

        </Form>
    );

};

export default LanguageEditForm;