import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages } from "components/pages";

export default function TranslatesRoute() {

    const { path } = useRouteMatch();
    
    return (
        <Switch>
            <Route path={`${path}`} exact>
                <Pages.TranslatesPage />
            </Route>
            <Route path={`${path}/page/:id`} exact>
                <Pages.TranslatesPage />
            </Route>
            <Route path={`${path}/generate-json`} exact>
                <Pages.TranslatesGenerateJsonPage />
            </Route>
            <Route path={`${path}/import`} exact>
                <Pages.TranslateImportPage />
            </Route>
        </Switch>
    );

}