import { gql } from '@apollo/client';


export const GET_GENERAL_INFO = gql`
    query GeneralInfo {
        generalInfo{
            system_info{
                label
                info
            }
            site_api{
                label
                info
            }
        }
    }
`;