import { HomeAppPage } from "./home";
import { LoginPage, ForgotPasswordPage } from "./authorization";
import { UsersPage, UserCreatePage, UserEditPage } from "./users";
import { LanguagesPage, LanguageEditPage, LanguageCreatePage } from "./languages";
import { TranslatesPage, TranslatesGenerateJsonPage, TranslateImportPage } from "./translates";
import { SystemInfoPage, SystemTestPage } from "./system";
import Page404 from "./404/404-page";

import "./pages.scss";


export const Pages = {
    HomeAppPage,
    UsersPage,
    UserCreatePage,
    UserEditPage,
    LanguagesPage,
    LanguageEditPage,
    LanguageCreatePage,
    TranslatesPage,
    TranslateImportPage,
    TranslatesGenerateJsonPage,
    SystemInfoPage,
    SystemTestPage,
    Page404
};

export const GuestPages = {
    LoginPage,
    ForgotPasswordPage,
}