import React from "react";
import { Card, Col, Row, Timeline } from 'antd';

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/wraps";

import './home.scss';


const HomeAppPage = () => {

    useActiveMenuItem( ["dashboard"] );
    
    return(
        <PageWrapDefault className="page-dashboard">
            
            <Row gutter={24} className="mb-3">
                <Col span={24}>
                    <Card type="inner" title="News" bordered={true}>
                        <Timeline className="mt-5" mode="left">
                            <Timeline.Item color="gold" label="24-08-2022"><p>Update added (*separator for import)</p><i>15:30:00</i></Timeline.Item>
                            <Timeline.Item color="gold" label="22-08-2022"><p>Update added (*return zip archive after export)</p><i>19:00:00</i></Timeline.Item>
                            <Timeline.Item color="green" label="09-05-2022"><p>Site launched</p><i>22:00:00</i></Timeline.Item>
                            {/* <Timeline.Item color="red" label="01-05-2022"><i>09:12:11</i> Network problems being solved</Timeline.Item> */}
                            <Timeline.Item color="gray" label="01-05-2022 - 08-05-2022">Technical testing</Timeline.Item>
                            <Timeline.Item label="01-05-2022">First release v.1.0.0</Timeline.Item>
                        </Timeline>
                    </Card>
                </Col>
            </Row>

            {/* <Row gutter={16} className="mb-3">
                <Col span={12}>
                    <Card type="inner" title="Card title" bordered={true}>
                    Card content
                    </Card>
                </Col>
                <Col span={12}>
                    <Card type="inner" title="Card title" bordered={true}>
                    Card content
                    </Card>
                </Col>
            </Row> */}
            {/* <Row gutter={16} className="mb-3">
                <Col span={8}>
                    <Card type="inner" title="Card title" bordered={true}>
                    Card content
                    </Card>
                </Col>
                <Col span={8}>
                    <Card type="inner" title="Card title" bordered={true}>
                    Card content
                    </Card>
                </Col>
                <Col span={8}>
                    <Card type="inner" title="Card title" bordered={true}>
                    Card content
                    </Card>
                </Col>
            </Row> */}

        </PageWrapDefault>
    )
};

export default HomeAppPage;