import React from 'react';
import { useMutation } from "@apollo/client";
import { Form, Input, Button } from 'antd';

import { USER_LOGIN } from "graphql/mutation/user-gql";
import { IS_LOGGED_IN } from "graphql/query/local-store-gql";

// import { FloatLabel } from "components/service";
import { useAuthRedirect } from "components/use-hooks";
import { errorNotification } from 'components/request-result';


const SignInForm = () => {

    const [ form ] = Form.useForm();
    const authRedirect = useAuthRedirect();

    const [ loginRequest, { loading } ] = useMutation(
        USER_LOGIN,
        {
            update(cache, { data : { userLogin } }) {

                const { access_token } = userLogin;

                localStorage.setItem("authToken", access_token);
                cache.writeQuery({
                    query: IS_LOGGED_IN,
                    data: {
                        isLoggedIn: access_token,
                    },
                });

                authRedirect();
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    const formItemLayout ={
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return (
        <div className="form-holder">
            <h1>
                Sign in with login <br /> and password
            </h1>
            <Form
                className="sign-in-form"
                form={ form }
                onFinish ={(values) => {
                    loginRequest({
                        variables : {
                            data : {
                                username: values.username,
                                password: values.password,
                            }
                        },
                    }).catch((error) => {
                        errorNotification(error);
                    });
                }}
            >
                <Form.Item
                    {...formItemLayout}
                    name="username"
                    // rules={[
                    //     {
                    //         type: 'email',
                    //         message: 'The input is not valid E-Mail',
                    //     },
                    //     {
                    //         required: true,
                    //         message: 'Please input your E-Mail'
                    //     }
                    // ]}
                >
                        <Input placeholder="email" name="username" />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Password!'
                        }
                    ]}
                >
                        <Input.Password
                            name="password"
                            placeholder="password"
                        />
                </Form.Item>
                <Form.Item>
                    <div className="form-actions">
                        {/* <Link
                            to="/forgot-password/"
                            className="login-form-link text-type2"
                        >
                            Forgot Password?
                        </Link> */}
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={ loading }
                        >
                            Login
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};


export default SignInForm;