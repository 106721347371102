import React, { useState } from 'react';
import { useMutation } from "@apollo/client";

import { Form, Button, Upload, Alert, List, Skeleton, Empty } from 'antd';

import { TRANSLATION_IMPORT } from 'graphql/mutation/translate-gql';
import { errorNotification, successNotification } from "components/request-result";
import { TranslateTypeSelectField, TranslateLangSelectField, TranslateSeparatorSelectField } from 'components/translate/fields';

import { UploadOutlined } from '@ant-design/icons';


const TranslateImportForm = ( { translationsType } ) => {

    const [ form ] = Form.useForm();

    const normFile = ( e: any ) => {
        if ( Array.isArray( e ) ) {
            return e;
        }
        return e && e.fileList;
    };

    const [translations, setTranslations] = useState( [] );

    const [translationImport, { loading }] = useMutation( TRANSLATION_IMPORT,
        {
            update(cache, { data }) {

                const {
                    translationImport : {
                        label,
                        message,
                        translations
                    }
                } = data;

                setTranslations( translations );

                successNotification({
                    title: label,
                    description: message
                });
                
            },
            onError(error){
                errorNotification(error);
            }

        }
    );

    return <>
        <Form
            key="translate-import"
            form={ form }
            initialValues={ {
                language_slug: 'en',
                separator: 'bottom_dash',
            } }
            onFinish={ ( values ) => {
            
                translationImport({
                    variables: {
                        type: values.type,
                        language_slug: values.language_slug,
                        separator: values.separator,
                        file: values.file[0].originFileObj,
                    }
                }).catch( ( error ) => {
                    errorNotification( error );
                });
                
            }}
            className="translate-import-form"
        >
            <div className="field-group">
           
                <TranslateTypeSelectField translationsType={ translationsType } />
                
                <TranslateLangSelectField /> 

                <TranslateSeparatorSelectField />

                <Form.Item
                    name="file"
                    label="Upload file"
                    valuePropName="fileList"
                    getValueFromEvent={ normFile }
                    extra=""
                    rules={[{ required: true }]}
                >
                    <Upload
                        accept="file"
                        // beforeUpload={ ( normFile ) => {

                        //     return false;

                        // }}
                        beforeUpload="false"
                        maxCount={1}
                        multiple={ false }
                        listType='text'
                    >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </Form.Item>

            </div>

            <div className="form-actions d-flex justify-content-between mt-4">
                <Alert 
                    className="fs-14 fw-500"
                    showIcon
                    message="WARNING *If the type and key match in the database, it will be overwritten from the new import" 
                    banner 
                />
                <Button
                    loading={ loading }
                    disabled={ loading }
                    type="primary"
                    htmlType="submit"
                >
                    Save
                </Button>
            </div>
        </Form>

        {/* { loading && <Skeleton active={true} paragraph={{ rows: 20 }} /> } */}
        
        <p className="mt-5"><b>Total: { !translations ? '0' : translations.length }</b></p>
        <List
            locale={{
                emptyText: loading ? <Skeleton active={true} paragraph={{ rows: 40 }} /> : <Empty />
            }}
            className="mt-2 list-translations"
            size="small"
            bordered={ true }
            dataSource={ translations }
            renderItem={
                item => <List.Item className={ `tr-item ${ item.status }` }>
                    <div className="tr-id">{ item.id }</div>
                    <div className="tr-type"><b>{ item.type }</b></div>
                    <div className="tr-language-slug">{ item.language_slug }</div>
                    <div className="tr-tr-group">{ item.tr_group }</div>
                    <div className="tr-tr-key">{ item.tr_key }</div>
                    <div className="tr-value">{ item.value }</div>
                </List.Item>
            }
        />

    </>

};

export default TranslateImportForm;