import UserFields from "components/user/fields";


const usersColumns = [
    {
        title: 'ID', dataIndex: 'id', columnIndex:'ID', sorter: true, align: 'center', width: '5%'
    },
    {
        title: 'Name', dataIndex: 'name', columnIndex:'NAME', sorter: true, align: 'center', width: '15%'
    },
    {
        title: 'Email', dataIndex: 'email', columnIndex:'EMAIL', sorter: true, align: 'center', width: '15%'
    },
    {
        title: 'Role', dataIndex: 'role', columnIndex:'ROLE', sorter: true, align: 'center', width: '10%'
    },
    {
        title: 'Status', dataIndex: 'status', columnIndex:'STATUS', sorter: true, align: 'center', width: '15%'
    },
    {
        title: 'Last visit', dataIndex: 'last_visit_at', columnIndex:'LAST_VISIT_AT', sorter: true, align: 'center', width: '15%'
    },
    {
        title: 'created at', dataIndex: 'created_at', columnIndex:'CREATED_AT', sorter: true, align: 'center', width: '15%'
    },
    {
        title: 'action', dataIndex: 'action', align: 'center', width: '10%'
    }
];


const usersData = ( users, variables ) => {

    if( !users ){
        return [];
    }

    return users.map( user => {

        return {
            key        : user.id,
            id         : user.id,
            name       : <UserFields.Online user={ user } />,
            email      : user.email,
            role       : user.role,
            status     : user.status,
            last_visit_at : <>{ user.last_visit_at ? user.last_visit_at : 'no visit' }</>,
            created_at : user.created_at,
            action     : <>
                <UserFields.EditLink user={ user } />
                <UserFields.DeleteButton user={ user } variables={ variables }/>
            </>,
        };

    });

};

export {
    usersColumns,
    usersData
};