import { gql } from '@apollo/client';

export const GET_LANGUAGES = gql`
    query GetLanguages(
        $status: Boolean
    ){
        languages(
            status: $status,
        ){
            id
            label
            slug
            status
            sync_date
        }
    }
`;


export const GET_LANGUAGE = gql`
    query GetLanguage(
        $id: ID,
    ) {
        language(
            id: $id,
        ){
            id
            label
            slug
            status
            sync_date
        }
    }
`;