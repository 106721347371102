import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from 'antd';

import { GET_TRANSLATIONS } from "graphql/query/translate-qql";
import { GET_TRANSLATIONS_SELECTED } from "graphql/query/translate-qql";
import { TRANSLATIONS_DELETE } from "graphql/mutation/translate-gql";

import { errorNotification, successNotification } from "components/request-result";


const TranslatesIDsDelete = ( { hasSelected, selectedIds, setSelectedIds } ) => {

    // const [ selectedIds, setSelectedIds ] = useState ( selectedIds );

    const [translationsDelete, { loading }] = useMutation( TRANSLATIONS_DELETE,
        {
            refetchQueries: [
                GET_TRANSLATIONS,
                GET_TRANSLATIONS_SELECTED
            ],
            
            update(cache, { data }) {
                
                setSelectedIds( [] );

                const {
                    translationsDelete : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });
                
            },
            onError(error){
                errorNotification(error);
            }

        }
    );

    return(
        <>
            <div style={{ marginBottom: 16 }}>
                <Button 
                    type="primary"
                    danger 
                    onClick={ () => {

                        translationsDelete({
                            variables: {
                                id: selectedIds
                            }
                        }).catch( ( error ) => {
                            errorNotification( error );
                        });
        
                    }}
                    disabled={ !hasSelected } 
                    loading={ loading }
                >
                    Delete
                </Button>
                <span style={{ marginLeft: 8 }}>
                    { hasSelected ? `Selected ${selectedIds.length} items` : '' }
                </span>
            </div>
        </>
    )

}


export default TranslatesIDsDelete;