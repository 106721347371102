import React from "react";
import { useHistory } from "react-router-dom";
import { PageHeader } from 'antd';

import UserForms from "components/user/forms";
import { PageWrapDefault } from "components/wraps";
import { useActiveMenuItem } from "components/use-hooks";


const UserCreatePage = () => {

    let history = useHistory();

    useActiveMenuItem( ["user-create"], ["users"] );

    return(
        <PageWrapDefault className="page-user single">

            <PageHeader
                className="site-page-header"
                onBack={ () => history.push( `/users` ) }
                title="Create User"
            />
            <div className="page-content single-content">
                <UserForms.Create />
                {/* <LanguageCreateForm /> */}
            </div>
                
        </PageWrapDefault>
    );
};

export default UserCreatePage;