import React from "react";
import { useQuery } from "@apollo/client";
import { Table, Row } from 'antd';

import { GET_LANGUAGES } from "graphql/query/language-qql";

import { LanguageEditLinkField, LanguageDeleteButtonField } from "../fields";
import { SkeletonTableData } from "components/skeleton";


const columns = [
    {
        title: 'ID', dataIndex: 'id', align: 'center', width: '5%'
    },
    {
        title: 'Label', dataIndex: 'label', align: 'center'
    },
    {
        title: 'Slug', dataIndex: 'slug', align: 'center'
    },
    {
        title: 'status', dataIndex: 'status', align: 'center'
    },
    {
        title: 'action', dataIndex: 'action', align: 'center'
    }
];

const LanguagesTable = () => {

    const { data : { languages } = {}, loading } = useQuery( GET_LANGUAGES, {
        // fetchPolicy: 'cache-and-network'
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
    } );

    const languageData = ( languages ) => {

        if( !languages ){
            return [];
        }
    
        return languages.map( language => {
            
            return {
                key: language.id,
                id: language.id,
                label: language.label,
                slug: language.slug,
                status: language.status ? 'active': 'not active',
                action: <>
                    <LanguageEditLinkField language={ language } />
                    <LanguageDeleteButtonField language={ language } />
                </>
            };
    
        });
    
    };
    
    return(
        <>
            <div className="table-header p-3">
                <Row className="title-header mb-4">
                    <h3>Languages</h3>

                    {/* <Button
                        type="primary">
                        New Language
                    </Button> */}

                </Row>

                <Row className="filter-header">
                    
                    <span className="float-right">total: { !languages ? '0' : languages.length } </span> 

                </Row>
            </div>

            <Table 
                className="table-languages"
                // locale={{
                //     emptyText: loading ? <Skeleton active={ true } paragraph={{ rows: 4 }} /> : <Empty />
                // }}
                dataSource={ loading ? SkeletonTableData( columns, 10 ) : languageData( languages ) }
                columns={ columns }
                scroll={ { x:true } }
                tableLayout='fixed'
                size="small"
                bordered={ true }
                pagination={ false }
            />
        </>
    );
};

export default LanguagesTable;