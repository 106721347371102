import React from "react";
import { Badge, Tooltip } from 'antd';

const UserOnlineField = ( { user } ) => {

    let status = 'success';
    let color  = 'green';
    let text   = 'online'; 

    if( user.is_online === false ){
        status = 'error';
        color  = 'red';
        text   = 'offline'; 
    }

    return(
        <>
            <Tooltip 
                placement="left" 
                title={`${text}`}
                color={ color } >
                    <Badge status={`${status}`} /> { user.name }
            </Tooltip>
        </>
    );

}

export default UserOnlineField;