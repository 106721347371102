import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Form, Input, Button, Switch } from 'antd';

import { LANGUAGE_CREATE } from "graphql/mutation/language-gql";
import { errorNotification, successNotification } from "components/request-result";


const LanguageCreateForm = () => {

    let history = useHistory();

    const [ form ] = Form.useForm();

    const [languageCreateRequest, { loading }] = useMutation( LANGUAGE_CREATE,
        {
            update(cache, { data }) {

                const {
                    languageCreate : {
                        label,
                        message,
                        language
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

                if( data?.languageCreate?.language.id ){

                    history.push( `/languages/${ language.id }` );
                   
                }

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(
        
        <Form
            key="create-language"
            initialValues={ {
                status: true
            } }
            form={ form }
            onFinish={ ( values ) => {
            
                languageCreateRequest({
                    variables: {
                        input: { ...values }
                    }
                }).catch((error) => {
                    errorNotification(error);
                });
                
            }}
            className="create-language"
        >

            <div className="field-group">

                <Form.Item 
                    name="label"
                    label="Label" 
                    rules={[
                        { required: true, message: 'Please input Label' }
                    ]}
                >
                    <Input name="label" />
                </Form.Item>

                <Form.Item 
                    name="slug"
                    label="Slug" 
                    rules={[
                        { required: true, message: 'Please input Slug' }
                    ]}
                >
                    <Input name="slug" />
                </Form.Item>

                <Form.Item 
                    label="Active" 
                    valuePropName="checked"
                    name="status"
                >
                    <Switch />
                </Form.Item>

            </div>

            <div className="form-actions text-right mt-4">
                <Button
                    loading={ loading }
                    disabled={ loading }
                    type="primary"
                    htmlType="submit"
                >
                    Save
                </Button>
            </div>
            

        </Form>
    );

};

export default LanguageCreateForm;