import React from "react";
import { Card, Col, Row } from 'antd';

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/wraps";

const SystemTestPage = () => {

    useActiveMenuItem( ["system-test"], ["system"] );

    return(
        <PageWrapDefault className="system-test-page">
            
            <Row gutter={16} className="mb-4">
                <Col span={24}>
                    <Card type="inner" bordered={true}>
                        <p>Test Page</p>
                        <p>Туту я чет буду делать пока не придумал, но все еще впереди :)</p>
                    </Card>
                </Col>
            </Row>

        </PageWrapDefault>
    )

}

export default SystemTestPage;