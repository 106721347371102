import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch, useHistory, Link } from "react-router-dom";
import { Table, Row, Input } from 'antd';

import { GET_USERS } from "graphql/query/user-gql";

import { SkeletonTableData } from "components/skeleton";
import { usersColumns, 
        usersData }          from "./users-table-helper";
import { UserAddOutlined }   from '@ant-design/icons';

const { Search } = Input;

const UsersTable = () => {
    
    const history = useHistory();

    let match = useRouteMatch( '/users/page/:currentPage' );

    const defaultOrderBy = { column: "CREATED_AT", order: "DESC" },
          currentPage    = match ? parseInt( match.params.currentPage ) : 1,
          perPage        = 10;

    const [ searchText, setSearchText ] = useState ( "" ),
          [ orderBy, setOrderBy ] = useState ( [ defaultOrderBy ] ),
          [ filters, setFilters ] = useState();

    const variables = {
        text: searchText,
        first: perPage,
        page: currentPage,
        orderBy,
        ...filters,
    };

    const { data : { users = {} } = {}, loading } = useQuery( GET_USERS, {
        variables: { ...variables },
        fetchPolicy: "cache-and-network"
    } );

    const { paginatorInfo } = users ?? {};

    return(
        <>
            <div className="table-header p-3">
                <Row className="title-header mb-4">

                    <h3>Users</h3>
                    <Link
                        className="ant-btn ant-btn-primary"
                        type="primary"
                        to={ '/users/create' }>
                        <UserAddOutlined /> New User
                    </Link>

                </Row>

                <Row className="filter-header">

                    <Search 
                        className="search-header w-100"
                        placeholder="Search for name, email"
                        onSearch={ ( value ) => setSearchText( value ) }  
                    />

                    <span className="float-right">total: { !users ? '0' : users.length } </span> 

                </Row>
            </div>

            <Table 
                className="table-users"
                dataSource={ loading ? SkeletonTableData( usersColumns, perPage ) : usersData( users.data, variables ) }
                onChange={ ( pagination, filters, { column, order } ) => {
                
                    if( column && order ){
                        
                        setOrderBy([
                            { 
                                column: column.columnIndex, 
                                order: order === "ascend" ? "ASC" : "DESC"  
                            }
                        ])

                    } else { setOrderBy( [ defaultOrderBy ] ) }

                }}
                columns={ usersColumns }
                scroll={ { x:true } }
                tableLayout='fixed'
                size="small"
                bordered={ true }
                pagination={{
                    position: ["bottomRight", "topRight"],
                    pageSize: perPage,
                    total: paginatorInfo?.total,
                    hideOnSinglePage: paginatorInfo?.total / paginatorInfo?.perPage > 1,
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/users` : `/users/page/${ page }`
                        history.push( path );
                    }
                }}
            />
        </>
    );
};

export default UsersTable;