import React from "react";
import { useHistory } from "react-router-dom";
import { PageHeader } from 'antd';

import { LanguageCreateForm } from "components/language/forms";
import { PageWrapDefault } from "components/wraps";
import { useActiveMenuItem } from "components/use-hooks";


const LanguageCreatePage = () => {

    let history = useHistory();

    useActiveMenuItem( ["language-create"], ["languages"] );

    return(
        <PageWrapDefault className="page-language single">

            <PageHeader
                className="site-page-header"
                onBack={ () => history.push( `/languages` ) }
                title="Create Language"
            />
            <div className="page-content single-content">
                <LanguageCreateForm />
            </div>
                
        </PageWrapDefault>
    );
};

export default LanguageCreatePage;