import { useQuery } from "@apollo/client";

import { GET_GENERAL_INFO } from "graphql/query/general-info.qql";

const useGeneralInfo = ( withStatus = false ) => {

    const { data : { generalInfo } = { }, loading, error } = useQuery( GET_GENERAL_INFO );

    if( withStatus ) return { generalInfo, loading, error };

    if( generalInfo ) return { generalInfo } ;

    return false;
}

export default useGeneralInfo;