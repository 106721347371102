import React from "react";
import { Row } from 'antd';

import { useActiveMenuItem } from "components/use-hooks";
import { UsersTable } from "components/user";

const UsersPage = () => {

    useActiveMenuItem( ["users-all"], ["users"] );

    return(
        <Row justify="center" className="page page-users fullscreen">
            
            <UsersTable />
            
        </Row>
    );
};

export default UsersPage;