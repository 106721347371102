import React from "react";
import { Tooltip } from 'antd';
import { Link } from "react-router-dom";
import { FormOutlined } from '@ant-design/icons';


const LanguageEditLinkField = ( { language } ) => {

    return(
        <Tooltip placement="top" title="Edit">
            <Link to={ `/languages/${ language.id }` } className="edit-link">
                <FormOutlined className="fs-20" />
            </Link>
        </Tooltip>
    );

};

export default LanguageEditLinkField;