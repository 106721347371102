import UserEditLinkField     from "./user-edit-link-field";
import UserDeleteButtonField from "./user-delete-button-field";
import UserOnlineField       from "./user-online-field";

const UserFields = {
    DeleteButton : UserDeleteButtonField,
    EditLink     : UserEditLinkField,
    Online       : UserOnlineField
}

export default UserFields;