import React from "react";
import { Card, Col, Row, Skeleton } from 'antd';

import { useGeneralInfo, useActiveMenuItem } from "components/use-hooks";
import { SystemInfo } from "components/widgets";
import { PageWrapDefault } from "components/wraps";

const SystemInfoPage = () => {

    useActiveMenuItem( ["system-information"], ["system"] );

    const { generalInfo, loading } = useGeneralInfo(true);

    return(
        <PageWrapDefault className="page-dashboard system-information-page">
            
            <Row gutter={16} className="mb-4">
                <Col span={12}>
                    <Card className="widget system-info-widgets" type="inner" title="system information" bordered={true}>
                        
                        { !loading &&
                            <SystemInfo generalInfo={ generalInfo } typeKey={ 'system_info' } />
                        }

                        { loading && <Skeleton active paragraph={{ rows: 10 }} /> }
                    </Card>
                </Col>
                <Col span={12}>
                    <Card className="widget site-api-widgets" type="inner" title="Sites & API" bordered={true}>
                        { !loading &&
                            <SystemInfo generalInfo={ generalInfo } typeKey={ 'site_api' } />
                        }
                        { loading && <Skeleton active paragraph={{ rows: 5 }} /> }
                        
                    </Card>
                </Col>
            </Row>

        </PageWrapDefault>
    )

}

export default SystemInfoPage;