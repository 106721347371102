import React from "react";
import { useQuery } from "@apollo/client";
import { Skeleton, Empty, PageHeader } from 'antd';

import { GET_LANGUAGE } from "graphql/query/language-qql";

import { LanguageEditForm } from "components/language/forms";
import { PageWrapDefault } from "components/wraps";
import { useActiveMenuItem } from "components/use-hooks";


const LanguageEditPage = ({ match, history }) => {

    useActiveMenuItem( ["languages-all"], ["languages"] );

    const languageID = match ? parseInt( match.params.id ) : null;

    const { data : { language } = {}, loading } = useQuery( GET_LANGUAGE, {
        variables: {
            id: languageID
        }
    });

    return(
        <PageWrapDefault className="page-language single">

            { loading && <Skeleton active={true} paragraph={{ rows: 15 }} /> }

            { !loading && !language && <Empty /> }

            { !loading && language && 
                <>
                    <PageHeader
                        className="site-page-header"
                        onBack={ () => history.push( `/languages` ) }
                        title="Edit Language"
                    />
                    <div className="page-content single-content">
                        <LanguageEditForm language={ language } />
                    </div>
                </>
            }

        </PageWrapDefault>
    );
};

export default LanguageEditPage;