import React, { useState } from 'react';
import { useQuery } from "@apollo/client";

import { Modal, Button } from 'antd';

import { GET_TRANSLATIONS_SELECTED } from "graphql/query/translate-qql";

import { TranslateCreateForm } from '../forms';
import { FileAddFilled }       from '@ant-design/icons';

const TranslateCreateModal = () => {

    const [isModalVisible, setIsModalVisible] = useState( false );

    const showModal = () => {
        setIsModalVisible( true );
    };
    
    // const handleOk = () => {
    //     setIsModalVisible( false );
    // };
    
    const handleCancel = () => {
        setIsModalVisible( false );
    };

    const { data : { translationSelected } = {} } = useQuery( GET_TRANSLATIONS_SELECTED, { fetchPolicy: 'network-only' } );

    return (
        <>
            <Button
                className="me-2" 
                type="primary" 
                onClick={ showModal }>
                <FileAddFilled /> New Translation
            </Button>
            <Modal 
                title="New Translation" 
                visible={ isModalVisible } 
                // onOk={ handleOk } 
                onCancel={ handleCancel }
                className="modal-translate-create"
                width={1000}
                footer={null}
            >
                <TranslateCreateForm translationSelected={ translationSelected } />
            </Modal>
        </>
      );

}


export default TranslateCreateModal;