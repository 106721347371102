import React from "react";
import { useMutation } from "@apollo/client";
import { Form, Input, Button, Select, Badge } from 'antd';

import { useLanguage } from "components/use-hooks";
import { USER_UPDATE } from "graphql/mutation/user-gql";
import { GET_USER }    from "graphql/query/user-gql";
import { errorNotification, successNotification } from "components/request-result";

import UserModel from "components/user/model";

const { Option } = Select;

const layout = {
    labelCol  : { span: 24 },
    wrapperCol: { span: 24 },
};

const UserEditForm = ( { user } ) => {

    const { languages } = useLanguage();
    const [ form ]      = Form.useForm();

    const [userUpdateRequest, { loading }] = useMutation( USER_UPDATE,
        {
            update(cache, { data }) {

                const {
                    userUpdate : {
                        label,
                        message,
                        user
                    }
                } = data;

                cache.writeQuery(  {
                    query: GET_USER,
                    data: {
                        user: {
                            ...user
                        }
                    }
                });

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    let color  = 'green';
    let text   = 'online'; 

    if( user.is_online === false ){
        color  = 'red';
        text   = 'offline'; 
    }

    return(
        <>
            <Badge.Ribbon className="badge-ribbon-user" text={`${text}`} color={ color } >
                <p className="mb-3"><i>Last visit: { user.last_visit_at ? user.last_visit_at : 'no visit' }</i></p>
            </Badge.Ribbon>
            <Form
                key="edit-user"
                initialValues={ {
                    name: user?.name,
                    email: user?.email,
                    role: user?.role,
                    status: user?.status,
                    lang: user?.lang
                } }
                form={ form }
                onFinish ={ ( values ) => {
                    userUpdateRequest({
                        variables: {
                            input: {
                                id: user.id,
                                ...values
                            }
                        }
                    }).catch( ( error ) => {
                        errorNotification( error );
                    });
                }}
                { ...layout } className="edit-user">

                <div className="field-group">
                    <Form.Item 
                        name="name"
                        label="Name" 
                        rules={[
                            { required: false, message: 'Please input name' }
                        ]}
                    >
                        <Input name="name" />
                    </Form.Item>

                    <Form.Item 
                        name="email" 
                        label="Email" 
                        rules={[{ type: 'email', required: false }]}
                    >
                        <Input name="email" />
                    </Form.Item>

                </div>

                <div className="field-group">

                    <Form.Item 
                        name="status"
                        label="Status"
                        initialValue="active"
                    >
                        <Select key="user-status">

                            { UserModel.Status.map( ( { text, value }, index ) => (
        
                                <Option key={ index } value={ value }>{ text }</Option>
                                
                            ))}

                        </Select>

                    </Form.Item>

                    <Form.Item 
                        name="role"
                        label="Role"
                        initialValue="guest"
                    >
                        <Select key="user-role" >

                            { UserModel.Roles.map( ( { text, value }, index ) => (
        
                                <Option key={ index } value={ value }>{ text }</Option>
                                
                            ))}

                        </Select>
                    </Form.Item>

                </div>

                <div className="field-group">

                    <Form.Item 
                        name="lang"
                        label="Language" 
                        rules={[
                            { required: false, message: 'Please input language' }
                        ]}
                    >
                        <Select 
                            key="lang"
                            className="select-language-slug"
                            placeholder="Select Language"
                        >

                            { languages && languages.map( ( { slug, label } ) => {

                                return <Option key={ slug } value={ slug }>{ label }</Option>;

                            })}

                        </Select>

                    </Form.Item>

                    <Form.Item 
                        name="password"
                        label="Password"
                        rules={[
                            { min: 8, message: 'Password must be at least 8 characters' },
                            { required: false, message: 'Please input password' }
                        ]}
                    >
                        <Input type="password" name="password" />
                    </Form.Item>

                </div>

                <div className="form-actions text-right mt-4">
                    <Form.Item >
                        <Button
                            loading={ loading }
                            disabled={ loading }
                            type="primary"
                            htmlType="submit"
                        >
                            Update
                        </Button>
                    </Form.Item>
                </div>

            </Form>
        </>
    );

};

export default UserEditForm;