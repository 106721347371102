import React from "react";
import { useQuery } from "@apollo/client";
import { Skeleton, Empty, PageHeader } from 'antd';

import { GET_USER } from "graphql/query/user-gql";

import UserForms from "components/user/forms";
import { PageWrapDefault }   from "components/wraps";
import { useActiveMenuItem } from "components/use-hooks";

const UserEditPage = ({ match, history }) => {

    useActiveMenuItem( ["users-all"], ["users"] );

    const userID = match ? parseInt( match.params.id ) : null;

    const { data : { user } = {}, loading } = useQuery( GET_USER, {
        variables: {
            id: userID
        }
    });

    return(
        <PageWrapDefault className="page-user single">

            { loading && <Skeleton active={true} paragraph={{ rows: 15 }} /> }

            { !loading && !user && <Empty /> }

            { !loading && user && 
                <>
                    <PageHeader
                        className="site-page-header"
                        onBack={ () => history.push( `/users` ) }
                        title="Edit Profile"
                    />
                    <div className="page-content single-content">
                        <UserForms.Edit user={ user } />
                    </div>
                </>
            }

        </PageWrapDefault>
    );
};

export default UserEditPage;