import { TranslateValueField } from "../fields";
import { Skeleton } from 'antd';

const translationsColumns = ( filters ) => {

    return [
        {
            title: 'ID', dataIndex: 'id', columnIndex:'ID', sorter: true, align: 'center', width: '5%'
        },
        {
            title: 'Type', dataIndex: 'type', columnIndex:'TYPE', sorter: true, align: 'center', width: '7%'
        },
        {
            title: 'Group', dataIndex: 'tr_group', columnIndex:'TR_GROUP', sorter: true, align: 'center', width: '10%'
        },
        {
            title: 'key', dataIndex: 'tr_key', columnIndex:'TR_KEY', sorter: true, align: 'center', width: '18%'
        },
        {
            title: `Translate ${filters.language_slug}`, dataIndex: 'from_value', className: "from-translate", align: 'center', width: '30%'
        },
        {
            title: `Translate ${filters.child_slug}`, dataIndex: 'to_value', align: 'center', width: '30%'
        }
    ];

}

const translationsEmptyData = ( columns, count = 30 ) => {
	
    const emptyDataJSX = [];
    const classSkl     = 'custom-skl';

    for ( var i = 0; i < count; i++ ){

        emptyDataJSX.push(
            {
                key       : `empty-data-${i}`,
                id        : <Skeleton active paragraph={ false } className={ classSkl }/>,
                type      : <Skeleton active paragraph={ false } className={ classSkl }/>,
                tr_group  : <Skeleton active paragraph={ false } className={ classSkl }/>,
                tr_key    : <Skeleton active paragraph={ false } className={ classSkl }/>,
                from_value: <Skeleton active paragraph={ false } className={ classSkl }/>,
                to_value  : <Skeleton active paragraph={ false } className={ classSkl }/>
            }
        );

    }

    return emptyDataJSX;

};

const translationsData = ( translations, filters ) => {

    const child_slug = filters.child_slug;

    if( !translations ){
        return [];
    }

    return translations.map( translation => {

        return {
            key       : translation.id,
            id        : translation.id,
            type      : translation.type,
            tr_group  : translation.tr_group,
            tr_key    : translation.tr_key,
            from_value: translation.value,
            to_value  : <TranslateValueField 
                childSlug         ={ child_slug } 
                translationParrent={ translation } 
                translationChild  ={ translation.childTranslation ? translation.childTranslation : {} } 
            />,
        };

    });

};

export {
    translationsColumns,
    translationsData,
    translationsEmptyData
};