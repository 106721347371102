import React from 'react';
import { Form, Select } from 'antd';

import { useLanguage } from "components/use-hooks";

const { Option } = Select;

const TranslateLangSelectField = () => {

    const { languages } = useLanguage();

    return (

        <Form.Item 
            name="language_slug"
            label="Language" 
            rules={[
                { required: true, message: 'Please input language' }
            ]}
        >
            <Select 
                key="language-slug"
                className="select-language-slug ms-2"
                placeholder="Select Language"
            >

                { languages && languages.map( ( { slug, label } ) => {

                    return <Option key={ slug } value={ slug }>{ label }</Option>;

                })}

            </Select>

        </Form.Item>
        
    );
}

export default TranslateLangSelectField;