import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from 'antd';

import { TRANSLATIONS_EXPORT } from "graphql/mutation/translate-gql";
import { errorNotification, successNotification } from "components/request-result";

import { DownloadOutlined } from '@ant-design/icons';


const TranslatesExport = ( { variables, filters } ) => {

    const [translationsExport, { loading }] = useMutation( TRANSLATIONS_EXPORT,
        {
            update(cache, { data }) {

                const {
                    translationsExport : {
                        label,
                        message,
                        file
                    }
                } = data;

                /*
                const link = document.createElement('a');

                fetch( file )
                    .then(res => res.json())
                    .then((fileData) => {
                        const url = URL.createObjectURL(
                            new Blob([JSON.stringify( fileData )], {
                                type: 'application/binary'}
                            )
                        )

                        console.log(url);

                        link.href = url;
                        link.download = 'test.json';
                        document.body.appendChild( link );
                        link.click();
                        document.body.removeChild( link );

                })
                .catch(err => { throw err });
                */
               
                const link = document.createElement('a');

                link.href = file;

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <Button
            className="ant-btn ant-btn-primary"
            type="primary"
            loading={ loading }
            disabled={ loading || !filters.type }
            onClick={ () => {

                translationsExport({
                    variables: { ...variables },
                }).catch( ( error ) => {
                    errorNotification( error );
                });

            } }
        >
            <DownloadOutlined /> Export JSON
        </Button>
    );

};

export default TranslatesExport;