import React from "react";
import { Row } from 'antd';

import { useActiveMenuItem } from "components/use-hooks";
import { TranslateTable } from "components/translate";

const TranslatesPage = () => {

    useActiveMenuItem( ["translations-all"], ["translations"] );

    return(
        <Row justify="center" className="page page-translates fullscreen">
            <TranslateTable />
        </Row>
    
    );
};

export default TranslatesPage;