import React from "react";
import { Switch, Route } from 'react-router-dom';

import { Pages } from "components/pages";
import UserRoute from "./users/user-route";
import LanguagesRoute from "./languages/language-route";
import TranslatesRoute from "./translates/translates-route";
import SystemPage from "./system/system-route";

const AppRoutes = () => {

    return(
        <Switch>
            <Route path="/" exact>
                <Pages.HomeAppPage />
            </Route>
            <Route path="/users">
                <UserRoute />
            </Route>
            <Route path="/languages">
                <LanguagesRoute />
            </Route>
            <Route path="/translations">
                <TranslatesRoute />
            </Route>
            <Route path="/system">
                <SystemPage />
            </Route>
            <Route path="*">
                <Pages.Page404 />
            </Route>
        </Switch>
    );
};

export default AppRoutes;