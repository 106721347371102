import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link } from "react-router-dom";

import './sidebar.scss';

const { SubMenu } = Menu;

const Sidebar = ( { appParams }) => {

    let {
        selectedKeys = ['dashboard'],
        openKeys     = ['dashboard']
    } =  appParams.activeMenu;

    const [ openSubMenu, setOpenSubMenu ] = useState( openKeys );

    useEffect( () => {

        setOpenSubMenu(openKeys)

    }, [appParams.activeMenu, openKeys] );

    return(
        <>
            <Menu
                mode="inline"
                selectedKeys={ selectedKeys }
                openKeys={ openSubMenu }
                onOpenChange={ ( keys ) => setOpenSubMenu(keys)}
                style={{ borderRight: 0 }}
            >
                <Menu.Item key="dashboard" >
                    <Link to="/">Dashboard</Link>
                </Menu.Item>

                <SubMenu key="users" title="Users">
                    <Menu.Item key="users-all"><Link to="/users">All Users</Link></Menu.Item>
                    <Menu.Item key="user-create"><Link to="/users/create">Add New</Link></Menu.Item>
                </SubMenu>

                <SubMenu key="languages" title="Languages">
                    <Menu.Item key="languages-all"><Link to="/languages">All Languages</Link></Menu.Item>
                    <Menu.Item key="language-create"><Link to="/languages/create">Add New</Link></Menu.Item>
                </SubMenu>

                <SubMenu key="translations" title="Translations">
                    <Menu.Item key="translations-all"><Link to="/translations">All Translations</Link></Menu.Item>
                    <Menu.Item key="import"><Link to="/translations/import">Import</Link></Menu.Item>
                    {/* <Menu.Item key="generate-json"><Link to="/translations/generate-json">Export</Link></Menu.Item> */}
                </SubMenu>
                
                <SubMenu key="system" title="System">
                    <Menu.Item key="system-information"><Link to="/system/information">Information</Link></Menu.Item>
                </SubMenu>

            </Menu>
        </>
    );
};

export default Sidebar;