import React from "react";
import { useHistory } from "react-router-dom";
import { PageHeader, Skeleton } from 'antd';
import { useQuery } from "@apollo/client";

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/wraps";
import { TranslateImportForm } from "components/translate/forms";

import { GET_TRANSLATIONS_SELECTED } from "graphql/query/translate-qql";


const TranslateImportPage = () => {

    let history = useHistory();

    const { data : { translationSelected } = {}, loading } = useQuery( GET_TRANSLATIONS_SELECTED );

    useActiveMenuItem( ["import"], ["translations"] );

    return(
        <PageWrapDefault className="page-translations single">

            <PageHeader
                className="site-page-header"
                onBack={ () => history.push( `/translations` ) }
                title="Import Translations"
            />
            <div className="page-content single-content">
                
                { loading && <Skeleton active={true} paragraph={{ rows: 9 }} /> }

                { !loading && 
                    <TranslateImportForm translationsType={ translationSelected.type } /> 
                }
                
            </div>
                
        </PageWrapDefault>
    );
};

export default TranslateImportPage;