import { gql } from '@apollo/client';


export const GET_ME = gql`
    query GetMe {
        me{
            id
            name
            email
            status
            role
            lang
            is_online
            last_visit_at
            created_at
            updated_at
        }
    }
`;

export const GET_USERS = gql`
    query GetUsers(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryUsersOrderByOrderByClause!],
        $where: QueryUsersWhereWhereConditions
    ){
        users(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                id
                name
                email
                status
                role
                lang
                is_online
                last_visit_at
                created_at
                updated_at
            }
        }
    }
`;



export const GET_USER = gql`
    query GetUser(
        $id: ID,
    ) {
        user(
            id: $id,
        ){
            id
            name
            email
            status
            role
            lang
            is_online
            last_visit_at
            created_at
            updated_at
        }
    }
`;