import React, { useState } from 'react';
import { Form, Input, Divider, Select, Typography, Space } from 'antd';

import { PlusOutlined } from '@ant-design/icons'


const { Option } = Select;

const TranslateTypeSelectField = ( { translationsType } ) => {

    let index   = 0;
    let types   = [];

    translationsType.map( type => {
            
        types.push( type.label );

    });

    const [items, setItems] = useState( types );
    const [name, setName] = useState('');

    const onNameChange = event => {
        setName( event.target.value );
    };

    const addItem = e => {
        e.preventDefault();
        setItems([...items, name || `New_${index++}`]);
        setName('');
    };

    return (
        <Form.Item 
            name="type" 
            label="Type" 
            // rules={[{ required: true }]}
            rules={[
                { required: true, message: 'Please input Type' }
            ]}
        >
            <Select
                style={{ width: 300 }}
                placeholder="Select type"
                dropdownRender={ menu => (
                    <>
                        { menu }
                        <Divider style={{ margin: '8px 0' }} />
                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                            <Input placeholder="Please enter Type" value={name} onChange={onNameChange} />
                            <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap' }}>
                            <PlusOutlined /> Add Type
                            </Typography.Link>
                        </Space>
                    </>
                )}
                >
                { items.map( item => (
                    <Option key={ item }>{ item }</Option>
                )) }
            </Select>
        </Form.Item>
    );
}

export default TranslateTypeSelectField;