import React from "react";
import { Row } from 'antd';

import { useActiveMenuItem } from "components/use-hooks";
import { LanguagesTable } from "components/language";

import './languages.scss';

const LanguagesPage = () => {

    useActiveMenuItem( ["languages-all"], ["languages"] );

    return(
        <Row justify="center" className="page page-languages fullscreen">

            <LanguagesTable />
            
        </Row>
    );
};

export default LanguagesPage;