import React from 'react';
import { useMutation } from "@apollo/client";

import { Form, Input, Button } from 'antd';

import { GET_TRANSLATIONS } from "graphql/query/translate-qql";
import { GET_TRANSLATIONS_SELECTED } from "graphql/query/translate-qql";
import { TRANSLATION_CREATE } from 'graphql/mutation/translate-gql';
import { errorNotification, successNotification } from "components/request-result";

import { TranslateTypeSelectField, TranslateLangSelectField, TranslateGroupSelectField } from 'components/translate/fields';


const TranslateCreateForm = ( { translationSelected } ) => {

    const [ form ] = Form.useForm();

    const [translationCreate, { loading }] = useMutation( TRANSLATION_CREATE,
        {
            refetchQueries: [
                GET_TRANSLATIONS,
                GET_TRANSLATIONS_SELECTED
            ],
            
            update(cache, { data }) {
        
                const {
                    translationCreate : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });
                
            },
            onError(error){
                errorNotification(error);
            }

        }
    );

    return (
        <>
            <Form
                key="translate-create"
                form={ form }
                initialValues={ {
                    language_slug: 'en',
                } }
                onFinish={ ( values ) => {
                    
                    translationCreate({
                        variables: {
                            input: { ...values }
                        }
                    }).catch( ( error ) => {
                        errorNotification( error );
                    });

                    form.resetFields();
                    
                }}
                className="translate-create"
                
            >
                <div className="field-group">

                    <TranslateTypeSelectField translationsType={ translationSelected.type } />

                    <TranslateGroupSelectField translationsGroup={ translationSelected.tr_group } />

                    <TranslateLangSelectField />

                </div>

                <div className="field-group">

                    <Form.Item 
                        name="tr_key"
                        label="Key" 
                        rules={[
                            { required: true, message: 'Please input Key' }
                        ]}
                    >
                        <Input style={{ width: 450 }} name="tr_key" />
                    </Form.Item>

                </div>

                <div className="field-group">
                    
                    <Form.Item 
                        name="value"
                        label="Value" 
                        rules={[
                            { required: true, message: 'Please input Value' }
                        ]}
                    >
                        <Input name="value" />
                    </Form.Item>

                </div>

                <div className="form-actions text-right mt-4">
                
                    <Button
                        loading={ loading }
                        disabled={ loading }
                        type="primary"
                        htmlType="submit"
                    >
                        Save
                    </Button>

                </div>
                    
            </Form>
        </>
    );

}

export default TranslateCreateForm;