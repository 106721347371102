import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Table, Input, Row /*, Skeleton, Empty*/ } from 'antd';

import { GET_TRANSLATIONS }     from "graphql/query/translate-qql";

import { SkeletonTableData }    from "components/skeleton";
import TranslatesTableFilter    from "./translates-table-filter";
import TranslatesExport         from "../translates-export";
import { translationsColumns, 
        translationsData }      from "./translates-table-helper";
import TranslatesIDsDelete      from "./translates-ids-delete";
import { TranslateCreateModal } from "../modal";

const { Search } = Input;

const TranslateTable = () => {

    const history = useHistory();

    let match = useRouteMatch( '/translations/page/:currentPage' );

    const defaultOrderBy = { column: "CREATED_AT", order: "DESC" },
        //  defaultParam = { type: 'core', language_slug: 'en', child_slug: 'en' },
          defaultParam   = { language_slug: 'en', child_slug: 'en' },
          currentPage    = match ? parseInt( match.params.currentPage ) : 1,
          perPage        = 50;

    const [ searchText, setSearchText ] = useState ( "" ),
          [ orderBy, setOrderBy ] = useState ( [ defaultOrderBy ] ),
          [ filters, setFilters ] = useState( defaultParam );

    const variables = {
        text: searchText,
        first: perPage,
        page: currentPage,
        orderBy,
        ...filters,
    };

    const { data : { translations = {} } = {}, loading } = useQuery( GET_TRANSLATIONS, {
        variables: { ...variables },
        fetchPolicy: 'network-only'
    } );

    const { paginatorInfo } = translations ?? {};

    const columns = translationsColumns( filters );

    /* Checkbox multi select */
    const [ selectedIds, setSelectedIds ] = useState ( [] );

    const rowSelection = {

        onChange: ( selectedRowKeys: React.Key[] ) => {
            setSelectedIds( selectedRowKeys );
        }
        
    };

    const hasSelected = selectedIds.length > 0;
    /* END Checkbox multi select */

    return(

        <>
            <div className="table-header p-3">

                <Row className="title-header mb-4">
                    <h3>Translations</h3>

                    <div className="d-flex">
                        <TranslateCreateModal />
                        <TranslatesExport variables={ variables } filters={ filters } />
                    </div>
                </Row>

                <Row className="filter-header mb-4">
                    
                    <Search 
                        className="search-header w-100"
                        placeholder="any search"
                        onSearch={ ( value ) => setSearchText( value ) }  
                    />

                    <TranslatesTableFilter setFilters={ setFilters } filters={ filters } />

                    <span className="float-right">total: { !paginatorInfo ? '0' : paginatorInfo?.total }</span>

                </Row>
       
                <TranslatesIDsDelete hasSelected={ hasSelected } selectedIds={ selectedIds } setSelectedIds={ setSelectedIds } />

            </div>

            <Table 
                rowSelection={ rowSelection }
                className="translates-table"
                // locale={{
                //     emptyText: loading ? <Skeleton active={true} paragraph={{ rows: 40 }} /> : <Empty />
                // }}
                onChange={ ( pagination, filters, { column, order } ) => {
                
                    if( column && order ){
                        
                        setOrderBy([
                            { 
                                column: column.columnIndex, 
                                order: order === "ascend" ? "ASC" : "DESC"  
                            }
                        ])

                    } else { setOrderBy( [ defaultOrderBy ] ) }

                }}
                dataSource={ 
                    loading ? SkeletonTableData( columns, perPage, 'my-1' )
                    : translationsData( translations.data, filters ) 
                }
                columns={ columns } 
                scroll={ { x:true } }
                bordered={ true }
                tableLayout='fixed'
                size="small"
                pagination={{
                    position: ["bottomRight", "topRight"],
                    pageSize: perPage,
                    total: paginatorInfo?.total,
                    hideOnSinglePage: paginatorInfo?.total / paginatorInfo?.perPage > 1,
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/translations` : `/translations/page/${ page }`
                        history.push( path );
                    }
                }}
            />
        </>

    );
}

export default TranslateTable;