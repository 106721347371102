import React from "react";
import { useQuery } from "@apollo/client";
import { Select } from 'antd';

import { useLanguage } from "components/use-hooks";
import { GET_TRANSLATIONS_SELECTED } from "graphql/query/translate-qql";


const { Option } = Select;

const TranslatesTableFilter = ( { setFilters, filters } ) => {

    const { languages } = useLanguage();

    const { data : { translationSelected } = {}, loading, error } = useQuery( GET_TRANSLATIONS_SELECTED, { fetchPolicy: 'network-only' } );

    return (
        <>
            <span>
                <b>Type</b>
                <Select
                    key="select-type-translate"
                    className="select-type-translate ms-2"
                    placeholder="Select Type Translation"
                    allowClear={ true }
                    onChange={ ( value ) => {

                        setFilters( { 
                            ...filters,
                            type : value,
                        })

                    }}
                    // defaultValue="core"
                >
                    { !loading && translationSelected.type.map( type => {
    
                        return (

                            <Option key={ type.label } value={ type.label }>{ type.label }</Option>
                            
                        );

                    })}
                </Select>
            </span>

            <span>
                <b>Group</b>
                <Select
                    key="group-translate"
                    className="select-group-translate tr-group ms-2"
                    placeholder="Select Group Translation"
                    allowClear={ true }
                    onChange={ ( value ) => {

                        setFilters( { 
                            ...filters,
                            tr_group : value,
                        })
                        
                    }}
                >
                    { !loading && translationSelected.tr_group.map( tr_group => {
    
                        return (

                            <Option key={ tr_group.label } value={ tr_group.label }>{ tr_group.label }</Option>
                            
                        );

                    })}
                </Select>
            </span>

            <span>
                <b>From</b>
                <Select 
                    key="parent-translate"
                    className="select-parent-translate ms-2"
                    placeholder="Select Parent Translation"
                    defaultValue="en"
                    onChange={ ( value ) => {

                        setFilters( { 
                            ...filters,
                            language_slug : value,
                        })

                    }}
                >

                    { languages && languages.map( ( { slug, label } ) => {

                        return <Option key={ slug } value={ slug }>{ label }</Option>;

                    })}

                </Select>
            </span>

            <span>
                <b>To</b>
                <Select
                    key="child-translate"
                    className="select-child-translate ms-2"
                    placeholder="Select Child Translation"
                    onChange={ ( value ) => {
                        
                        setFilters( { 
                            ...filters,
                            child_slug : value,
                        })

                    }}
                    defaultValue="en"
                >
                    { languages && languages.map( ( { slug, label } ) => {

                        return <Option key={ slug } value={ slug }>{ label }</Option>;

                    })}

                </Select>
            </span>

        </>
    );

};

export default TranslatesTableFilter;