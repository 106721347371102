import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Popconfirm } from 'antd';

import { GET_LANGUAGES } from "graphql/query/language-qql";
import { LANGUAGE_DELETE } from "graphql/mutation/language-gql";

import { errorNotification, successNotification } from "components/request-result";
import { DeleteOutlined } from '@ant-design/icons';


const LanguageDeleteButtonField = ( { language } ) => {

    const [visible, setVisible] = React.useState( false );

    const [languageDeleteRequest, { loading }] = useMutation( LANGUAGE_DELETE,
        {
            update(cache, { data }) {

                const {
                    languageDelete : {
                        label,
                        message
                    }
                } = data;
                
                const { languages } = cache.readQuery({
                    query: GET_LANGUAGES
                });
            
                cache.writeQuery({
                    query: GET_LANGUAGES,
                    data: {
                        ...languages,
                        languages: [
                            ...languages.filter( ( item ) => item.id !== language.id )
                        ]
                    },
                });

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(
        <>
            <Popconfirm
                
                title={ <>Are you sure you want to delete the language - <b>{ language.label } ID:{ language.id }?</b></> }
                visible={ visible }
                onConfirm={ () => {
                    languageDeleteRequest({
                        variables: {
                            id: language.id
                        }
                    })
                }}
                okButtonProps={ { loading } }
                onCancel={ () => setVisible( false ) }
                >
                <Button
                    className="button-delete"
                    type="text"
                    onClick={ () => setVisible( true ) }
                >
                    <DeleteOutlined className="fs-20" />
                </Button>
            </Popconfirm>
        </>
    );

};

export default LanguageDeleteButtonField;