import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Popconfirm } from 'antd';

import { GET_USERS }   from "graphql/query/user-gql";
import { USER_DELETE } from "graphql/mutation/user-gql";

import { errorNotification, 
         successNotification } from "components/request-result";
import { DeleteOutlined }      from '@ant-design/icons';


const UserDeleteButtonField = ( { user, variables } ) => {

    const [visible, setVisible] = React.useState( false );

    const [userDeleteRequest, { loading }] = useMutation( USER_DELETE,
        {
            update( cache, { data } ) {

                const {
                    userDelete : {
                        label,
                        message
                    }
                } = data;
                
                const { users } = cache.readQuery({
                    query: GET_USERS,
                    variables
                });
            
                cache.writeQuery({
                    query: GET_USERS,
                    variables,
                    data: {
                        users: {
                            ...users,
                            paginatorInfo:{
                                ...users.paginatorInfo,
                                total: users.paginatorInfo.total - 1
                            },
                            data : [
                                ...users.data.filter( ( item ) => item.id !== user.id )
                            ]
                        }
                    },
                });

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(
        <>
            <Popconfirm
                
                title={ <>Are you sure you want to delete the user - <b>{ user.name } ID:{ user.id }?</b></> }
                visible={ visible }
                onConfirm={ () => {
                    userDeleteRequest({
                        variables: {
                            id: user.id
                        }
                    })
                }}
                okButtonProps={ { loading } }
                onCancel={ () => setVisible( false ) }
                >
                <Button
                    className="button-delete"
                    type="text"
                    onClick={ () => setVisible( true ) }
                >
                    <DeleteOutlined className="fs-20" />
                </Button>
                
            </Popconfirm>
        </>
    );

};

export default UserDeleteButtonField;